import React from "react";
import Map from "./Map";
import blogImage from "../images/img-Sanitation.jpg";
const EventsDetailSanitation = () => {
  return (
    <section className="event-detail-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-content">
              <div className="blog-item">
                <div className="blog-img">
                  <img src={blogImage} alt="" />
                  <span className="blog__tag">
                    <span className="date__num-text">9</span>
                    <span className="date__mon-text">mar</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="event-detail-content">
              <div className="event-detail-item">
                <h3 className="event__title">Play for the world</h3>
                <p className="event__text">
                  Purba Dhansara Jote Jana kalyan Samity (NGO) is a local
                  organization that works for health and family social welfare
                  in Darjeeling district rural area. One of their projects is
                  the sanitation program, which aims to improve the
                  effectiveness of rural water supply and sanitation services
                  through decentralization and increased role of Panchayati Raj
                  Institutions and involvement of local communities.
                </p>
                <p className="event__text">
                  The sanitation program is a World Bank aided program that
                  provides technical and financial support to the NGO for
                  constructing and maintaining toilets, water tanks, pipelines,
                  soak pits, hand pumps and other sanitation facilities in the
                  rural areas. The program also conducts awareness campaigns,
                  training sessions, workshops and rallies to educate the people
                  about the importance of hygiene, sanitation and safe drinking
                  water.
                </p>
              </div>
              <div className="event-detail-item">
                <h3 className="event__title event__title2">
                  Event Requirements
                </h3>
                <p className="event__text">
                  The sanitation program is a commendable effort by Purba
                  Dhansara Jote Jana kalyan Samity (NGO) to enhance the quality
                  of life and health of the rural population. The program has
                  brought many benefits, such as reducing water-borne diseases,
                  improving environmental conditions, empowering women and
                  children, and promoting social harmony. The program
                  has also received appreciation from the World Bank, the state
                  government and the local authorities for its successful
                  implementation and impact.
                </p>
              </div>
              <div className="event-detail-btn">
                <a href="#none" className="theme-btn">
                  register now
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="event-detail-sidebar">
              <div className="event-detail-item">
                <h3 className="event__title">Event Details</h3>
                <ul className="event__list">
                  <li>
                    <span>Starting Time:</span> 8:00AM to 2:00PM
                  </li>
                  <li>
                    <span>Date:</span>9 March, 2019
                  </li>
                  <li>
                    <span>Category:</span>Health
                  </li>
                  <li>
                    <span>Phone:</span>+91-9933086710
                  </li>
                  <li>
                    <span>Website:</span>info@pdjjks.org
                  </li>
                  <li>
                    <span>Location:</span>Siliguri, West Bengal, India
                  </li>
                </ul>
              </div>
              <div className="event-detail-item event-detail-item2">
                <Map />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsDetailSanitation;
